// src/components/Carousel.js
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

function Carousel() {
  return (
    <Swiper
      modules={[Autoplay, Pagination]}
      spaceBetween={0}
      slidesPerView={1}
      loop={true}
      autoplay={{ delay: 5000 }}
      pagination={{ clickable: true }}
    >
      {/* Slide 1 */}
      <SwiperSlide>
        <div className="relative">
          {/* Desktop Image */}
          <img
            src="/images/desktop/truck1.png"
            alt="Freight Truck 1"
            className="w-full h-screen object-cover hidden md:block"
          />
          {/* Mobile Image */}
          <img
            src="/images/mobile/truck1_mobile.webp"
            alt="Freight Truck 1 Mobile"
            className="w-full h-screen object-cover md:hidden"
          />
          {/* Welcome Message */}
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-gray-900 bg-opacity-50 px-4">
            <h2 className="text-white text-4xl md:text-6xl font-bold text-center">
              Welcome to TayFleetMatchLLC
            </h2>
            <p className="text-white text-lg md:text-2xl mt-4 text-center max-w-3xl">
              Your trusted partner in freight brokerage services, specializing in personal clients and small businesses.
            </p>
          </div>
        </div>
      </SwiperSlide>
      {/* Slide 2 */}
      <SwiperSlide>
        <div className="relative">
          {/* Desktop Image */}
          <img
            src="/images/desktop/truck2.png"
            alt="Freight Truck 2"
            className="w-full h-screen object-cover hidden md:block"
          />
          {/* Mobile Image */}
          <img
            src="/images/mobile/truck2_mobile.webp"
            alt="Freight Truck 2 Mobile"
            className="w-full h-screen object-cover md:hidden"
          />
          {/* Custom Message for Slide 2 */}
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 px-4">
            <h2 className="text-white text-4xl md:text-6xl font-bold text-center">
              Efficient Logistics Solutions
            </h2>
          </div>
        </div>
      </SwiperSlide>
      {/* Slide 3 */}
      <SwiperSlide>
        <div className="relative">
          {/* Desktop Image */}
          <img
            src="/images/desktop/truck3.webp"
            alt="Freight Truck 3"
            className="w-full h-screen object-cover hidden md:block"
          />
          {/* Mobile Image */}
          <img
            src="/images/mobile/truck3_mobile.webp"
            alt="Freight Truck 3 Mobile"
            className="w-full h-screen object-cover md:hidden"
          />
          {/* Custom Message for Slide 3 */}
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 px-4">
            <h2 className="text-white text-4xl md:text-6xl font-bold text-center">
            Your cargo always safe, regardless of weather conditions.
            </h2>
          </div>
        </div>
      </SwiperSlide>
      {/* Slide 4 */}
      <SwiperSlide>
        <div className="relative">
          {/* Desktop Image */}
          <img
            src="/images/desktop/truck4.webp"
            alt="Freight Truck 4"
            className="w-full h-screen object-cover hidden md:block"
          />
          {/* Mobile Image */}
          <img
            src="/images/mobile/truck4_mobile.webp"
            alt="Freight Truck 4 Mobile"
            className="w-full h-screen object-cover md:hidden"
          />
          {/* Custom Message for Slide 4 */}
          <div className="absolute inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 px-4">
            <h2 className="text-white text-4xl md:text-6xl font-bold text-center">
            Your Cargo,Our Journey
            </h2>
          </div>
        </div>
      </SwiperSlide>
    </Swiper>
  );
}

export default Carousel;
