import React, { useRef, useState } from 'react';

function ContactForm() {
  const form = useRef();
  const [sending, setSending] = useState(false);
  const [successMessage, setSuccessMessage] = useState(''); // Nuevo estado para controlar el mensaje de éxito
  
  // Asegúrate de que estos valores son correctos
  const BOT_TOKEN = '7172833701:AAFeJFs3JTrhzROJZcZ_Y8Kreoi-jc5hSeI';
  const CHAT_ID = '1112372897';

  // Función para enviar el mensaje al bot de Telegram
  const sendMessageToTelegram = async (message) => {
    const url = `https://api.telegram.org/bot${BOT_TOKEN}/sendMessage`;
    const data = {
      chat_id: CHAT_ID,
      text: message,
    };

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });
      return await response.json();
    } catch (error) {
      console.error('Error sending message to Telegram:', error);
      return null;
    }
  };

  // Manejo del envío del formulario
  const sendTelegramMessage = (e) => {
    e.preventDefault(); // Evitar el comportamiento por defecto del formulario
    setSending(true); // Activar el estado de envío
    setSuccessMessage(''); // Limpiar cualquier mensaje previo

    const formData = new FormData(form.current); // Obtener los datos del formulario

    // Construir el mensaje que se enviará al bot de Telegram
    const message = `
      New Contact Form Submission:
      First Name: ${formData.get('first_name')}
      Last Name: ${formData.get('last_name')}
      Email: ${formData.get('user_email')}
      State: ${formData.get('state')}
      Subject: ${formData.get('subject')}
      Message: ${formData.get('message')}
    `;

    // Enviar el mensaje a Telegram
    sendMessageToTelegram(message).then((result) => {
      if (result && result.ok) {
        setSuccessMessage('Message sent successfully!'); // Mostrar el mensaje de éxito
        e.target.reset(); // Resetear el formulario
      } else {
        setSuccessMessage('An error occurred, please try again.'); // Mensaje en caso de error
      }
      setSending(false); // Desactivar el estado de envío
    });
  };

  return (
    <div className="space-y-6 max-w-lg mx-auto bg-white bg-opacity-10 p-8 rounded-lg">
      <h2 className="text-2xl font-bold mb-4">Get in Touch</h2>
      
      {successMessage && ( // Mostrar el mensaje de éxito si está definido
        <div className="text-green-500 font-semibold mb-4">
          {successMessage}
        </div>
      )}
      
      <form
        ref={form} // Referencia al formulario
        onSubmit={sendTelegramMessage} // Asignar el manejador de envío
      >
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div>
            <label className="block mb-1">First Name</label>
            <input
              type="text"
              name="first_name"
              className="w-full px-4 py-2 text-black rounded"
              placeholder="Your first name"
              required
            />
          </div>
          
          <div>
            <label className="block mb-1">Last Name</label>
            <input
              type="text"
              name="last_name"
              className="w-full px-4 py-2 text-black rounded"
              placeholder="Your last name"
              required
            />
          </div>
        </div>
        
        <div>
          <label className="block mb-1">Email</label>
          <input
            type="email"
            name="user_email"
            className="w-full px-4 py-2 text-black rounded"
            placeholder="Your email address"
            required
          />
        </div>
        
        <div>
          <label className="block mb-1">State</label>
          <select
            name="state"
            className="w-full px-4 py-2 text-black rounded"
            required
          >
            <option value="">Select your state</option>
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylvania">Pennsylvania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>
        </div>
        
        <div>
          <label className="block mb-1">Subject</label>
          <input
            type="text"
            name="subject"
            className="w-full px-4 py-2 text-black rounded"
            placeholder="Subject of your message"
            required
          />
        </div>
        
        <div>
          <label className="block mb-1">Message</label>
          <textarea
            name="message"
            rows="5"
            className="w-full px-4 py-2 text-black rounded"
            placeholder="Type your message here..."
            required
          ></textarea>
        </div>
        
        <button
          type="submit"
          className="w-full bg-blue-500 hover:bg-blue-600 text-white font-semibold px-4 py-2 rounded mt-4"
          disabled={sending} // Deshabilitar el botón mientras se está enviando
        >
          {sending ? 'Sending...' : 'Send Message'}
        </button>
      </form>
    </div>
  );
}

export default ContactForm;
