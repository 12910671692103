// src/components/Navbar.js
import { useState } from 'react';
import { Link } from 'react-scroll';

function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const NAVBAR_HEIGHT = 80; // Adjust according to actual navbar height

  const toggleMenu = () => setIsOpen(!isOpen);

  const handleLinkClick = () => {
    setIsOpen(false);
  };

  return (
    <nav className="bg-gray-900 fixed w-full top-0 z-50">
      <div className="container mx-auto flex justify-between items-center p-4">
        <div className="text-2xl font-bold">
          <Link to="home" smooth duration={500} offset={-NAVBAR_HEIGHT} className="text-white cursor-pointer">
            TayFleetMatchLLC
          </Link>
        </div>
        <div className="md:hidden">
          <button onClick={toggleMenu}>
            <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor">
              {isOpen ? (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              ) : (
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              )}
            </svg>
          </button>
        </div>
        <ul className="hidden md:flex md:items-center md:space-x-6">
          <li>
            <Link
              to="home"
              smooth
              duration={500}
              offset={-NAVBAR_HEIGHT}
              className="text-white hover:text-blue-400 cursor-pointer"
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              to="about"
              smooth
              duration={500}
              offset={-NAVBAR_HEIGHT}
              className="text-white hover:text-blue-400 cursor-pointer"
            >
              About Us
            </Link>
          </li>
          <li>
            <Link
              to="contact"
              smooth
              duration={500}
              offset={-NAVBAR_HEIGHT}
              className="text-white hover:text-blue-400 cursor-pointer"
            >
              Contact Us
            </Link>
          </li>
        </ul>
      </div>
      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-gray-900 bg-opacity-95 fixed inset-0 flex flex-col items-center justify-center">
          <button onClick={toggleMenu} className="absolute top-4 right-4 text-white">
            <svg className="w-8 h-8" fill="none" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
          <ul className="space-y-6 text-center">
            <li>
              <Link
                to="home"
                smooth
                duration={500}
                onClick={handleLinkClick}
                offset={-NAVBAR_HEIGHT}
                className="text-white text-2xl cursor-pointer hover:text-blue-400"
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="about"
                smooth
                duration={500}
                onClick={handleLinkClick}
                offset={-NAVBAR_HEIGHT}
                className="text-white text-2xl cursor-pointer hover:text-blue-400"
              >
                About Us
              </Link>
            </li>
            <li>
              <Link
                to="contact"
                smooth
                duration={500}
                onClick={handleLinkClick}
                offset={-NAVBAR_HEIGHT}
                className="text-white text-2xl cursor-pointer hover:text-blue-400"
              >
                Contact Us
              </Link>
            </li>
          </ul>
        </div>
      )}
    </nav>
  );
}

export default Navbar;
