// src/components/Testimonials.js
function Testimonials() {
    return (
      <div className="container mx-auto p-4">
        <h2 className="text-3xl font-bold text-center mb-6">What Our Clients Say</h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Testimonial 1 */}
          <div className="p-4 bg-white bg-opacity-10 rounded shadow">
            <p className="italic">
              "TayFleetMatchLLC's service exceeded our expectations. Highly recommended."
            </p>
            <p className="mt-4 text-right font-semibold">- Satisfied Client</p>
          </div>
          {/* Testimonial 2 */}
          <div className="p-4 bg-white bg-opacity-10 rounded shadow">
            <p className="italic">
              "Excellent customer service and efficient logistics solutions."
            </p>
            <p className="mt-4 text-right font-semibold">- Small Business Owner</p>
          </div>
          {/* Testimonial 3 */}
          <div className="p-4 bg-white bg-opacity-10 rounded shadow">
            <p className="italic">
              "We can always count on TayFleetMatchLLC for our shipping needs."
            </p>
            <p className="mt-4 text-right font-semibold">- Entrepreneur</p>
          </div>
        </div>
      </div>
    );
  }
  
  export default Testimonials;
  