// src/pages/Home.js
import Carousel from '../components/Carousel';
import ContactForm from '../components/ContactForm';
import Testimonials from '../components/Testimonials';

function Home() {
  return (
    <div>
      {/* Hero Section with Carousel and Welcome Message */}
      <section id="home" className="min-h-screen">
        <Carousel />
      </section>

      {/* About Us Section */}
      <section
        id="about"
        className="min-h-screen flex flex-col justify-center items-center text-center px-4"
      >
        <div className="max-w-3xl">
          <h1 className="text-3xl font-bold mb-4">About Us</h1>
          <p className="mb-8">
            At TayFleetMatchLLC, we are dedicated to connecting our clients with reliable transportation that is fast and efficient, ensuring every load reaches its drop-off location safely.
          </p>
          {/* Mission, Vision, Values */}
          <div className="space-y-8">
            {/* Mission */}
            <div>
              <h2 className="text-2xl font-semibold">Mission</h2>
              <p>
                Connect our clients with reliable transportation, fast and efficient, making sure every load makes it to its drop-off location safely.
              </p>
            </div>
            {/* Vision */}
            <div>
              <h2 className="text-2xl font-semibold">Vision</h2>
              <p>
                Be a leader in the transportation business, known for our commitment to excellence and creating long-standing relationships based on trust and results.
              </p>
            </div>
            {/* Values */}
            <div>
              <h2 className="text-2xl font-semibold">Values</h2>
              <ul className="list-disc list-inside space-y-2 text-left">
                <li>
                  <strong>Trust:</strong> We guarantee the timeliness and punctuality of every load.
                </li>
                <li>
                  <strong>Efficiency:</strong> We optimize the delivery process to ensure quick and affordable services.
                </li>
                <li>
                  <strong>Connection:</strong> We unite transport professionals and clients to find effective solutions.
                </li>
                <li>
                  <strong>Transparency:</strong> We operate with integrity and clarity in every transaction.
                </li>
              </ul>
            </div>
            {/* Goal */}
            <div>
              <h2 className="text-2xl font-semibold">Goal</h2>
              <p>
                We strive to exceed our service expectations in every interaction.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Contact Us Section */}
      <section
        id="contact"
        className="min-h-screen flex flex-col justify-center items-center text-center px-4"
      >
        <div className="max-w-3xl">
          <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
          <p className="mb-8">
            If you have any questions or need more information about our services, please fill out the form below, and we will get back to you as soon as possible.
          </p>
          <ContactForm />
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="min-h-screen flex flex-col justify-center items-center text-center px-4">
        <Testimonials />
      </section>
    </div>
  );
}

export default Home;
