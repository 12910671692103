// src/components/Footer.js
import { FaFacebookF, FaTwitter, FaLinkedinIn, FaInstagram, FaTiktok } from 'react-icons/fa';

function Footer() {
  return (
    <footer className="bg-gray-900 text-white">
      <div className="container mx-auto p-4 text-center">
        <div className="flex justify-center space-x-6 mb-4">
          <a href="https://www.tiktok.com/@freightbroker04?_t=8pkXlPVwdop&_r=1" target="_blank" rel="noopener noreferrer">
            <FaTiktok className="h-6 w-6 hover:text-blue-500" />
          </a>
          <a href="https://www.instagram.com/tayfleetmatch?igsh=dndrbTR2YW82cTY1" target="_blank" rel="noopener noreferrer">
            <FaInstagram className="h-6 w-6 hover:text-pink-500" />
          </a>
        </div>
        <p>&copy; {new Date().getFullYear()} TayFleetMatchLLC. All rights reserved.</p>
      </div>
    </footer>
  );
}

export default Footer;
