// src/components/FloatingButton.js
import { Link } from 'react-scroll';

function FloatingButton() {
  const NAVBAR_HEIGHT = 80; // Same as in Navbar.js

  return (
    <Link
      to="contact"
      smooth
      duration={500}
      offset={-NAVBAR_HEIGHT}
      className="fixed bottom-4 right-4 bg-blue-500 text-white p-4 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none cursor-pointer"
      aria-label="Contact Us"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="h-6 w-6"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        {/* Contact Icon */}
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          d="M21 8a8.001 8.001 0 01-15.874 2H3l3.757-3.757a1 1 0 011.414 0L12 11l4.829-4.829a1 1 0 011.414 0L21 8z"
        />
      </svg>
    </Link>
  );
}

export default FloatingButton;
